import React, { Suspense } from 'react'
import { Layout } from 'antd'
import { Router, Route, Redirect, Switch } from 'react-router-dom'
import { Provider } from 'react-redux'
import { v4 as uuid } from 'uuid'

import history from './history'
import logger from './services/errorlogger'
import initiateCirsp from './services/crisp'

import createStore from './store/createStore'

import AuthenticationMonitor from './auth/AuthMonitor'
import PrivateRoute from './auth/PrivateRoute.jsx'

import UpgradeModal from './components/UpgradeModal/UpgradeModal'
import Header from './components/Header/Header'
import Footer from './components/Footer/Footer'
import Loading from 'components/Loading/Loading'

import './App.css'

const DashboardPage = React.lazy(() => import('./pages/Dashboard/DashboardPage'))
const SiteManagerPage = React.lazy(() => import('./pages/SiteManager/SiteManagerPage'))
const SiteReportFullPage = React.lazy(() => import('./pages/SiteReport/SiteReportFullPage'))
const SiteReportDemoPage = React.lazy(() => import('./pages/SiteReport/SiteReportDemoPage'))
const AccountPage = React.lazy(() => import('./pages/Account/AccountPage'))
const NotificationsPage = React.lazy(() => import('./pages/Notifications/NotificationsPage'))
const AdminPage = React.lazy(() => import('./pages/Admin/AdminPage'))
const LoginPage = React.lazy(() => import('./pages/Auth/LoginPage'))
const LogoutPage = React.lazy(() => import('./pages/Auth/LogoutPage'))
const SignupPage = React.lazy(() => import('./pages/Auth/SignupPage'))
const VerifyEmailPage = React.lazy(() => import('./pages/Auth/VerifyEmailPage'))
const AccountInactivePage = React.lazy(() => import('./pages/Auth/AccountInactivePage'))
const PricingPage = React.lazy(() => import('./pages/Pricing/PricingPage'))
const CheckoutPage = React.lazy(() => import('./pages/Checkout/CheckoutPage'))
const PaymentConfirmationPage = React.lazy(() => import('./pages/Checkout/PaymentConfirmationPage'))
const PaymentSuccessPage = React.lazy(() => import('./pages/Checkout/PaymentSuccessPage'))
const OnboardingPage = React.lazy(() => import('./pages/Onboarding/OnboardingPage'))

const store = createStore()

const isProduction = process.env.NODE_ENV === 'production'

logger.init()

const App = () => {
  if (isProduction) initiateCirsp()
  if (localStorage && !localStorage.getItem('sessionId')) localStorage.setItem('sessionId', uuid())

  return (
    <Provider store={store}>
      <div className="App">
        <Router history={history}>
          <AuthenticationMonitor>
            <Layout>
              <Header />
              <Layout>
                <Layout.Content className="app-content">
                  <Suspense fallback={<Loading />}>
                    <Switch>
                      <Route
                        path="/"
                        exact
                        component={(props) => (
                          <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
                        )}
                      />
                      <Route path="/login/" component={LoginPage} />
                      <Route path="/logout/" component={LogoutPage} />
                      <Route exact path="/sign-up/" component={SignupPage} />
                      <Route exact path="/verify/" component={VerifyEmailPage} />
                      <Route
                        exact
                        path="/verify/:userId/:emailVerifyId"
                        component={VerifyEmailPage}
                      />
                      <Route path="/inactive/" component={AccountInactivePage} />

                      <Route exact path="/start/" component={SiteReportDemoPage} />
                      <Route exact path="/start/:siteUrl" component={SiteReportDemoPage} />

                      <Route exact path="/app/pricing/" component={PricingPage} />
                      <Route exact path="/app/checkout/" component={CheckoutPage} />
                      <Route
                        exact
                        path="/app/payment/confirmation/"
                        component={PaymentConfirmationPage}
                      />
                      <Route exact path="/app/payment/success/" component={PaymentSuccessPage} />

                      <PrivateRoute exact path="/app/dashboard/" component={DashboardPage} />
                      <PrivateRoute exact path="/app/onboarding/" component={OnboardingPage} />
                      <PrivateRoute exact path="/app/site/:siteId" component={SiteManagerPage} />
                      <PrivateRoute
                        path="/app/report/:siteId/:jobId"
                        component={SiteReportFullPage}
                      />
                      <PrivateRoute path="/app/account/" component={AccountPage} />
                      <PrivateRoute
                        exact
                        path="/app/notifications/"
                        component={NotificationsPage}
                      />

                      <PrivateRoute exact path="/app/admin/" component={AdminPage} />

                      <Redirect to="/app/dashboard/" />
                    </Switch>
                  </Suspense>
                </Layout.Content>
              </Layout>
              <Footer />
              <UpgradeModal />
            </Layout>
          </AuthenticationMonitor>
        </Router>
      </div>
    </Provider>
  )
}

export default App
