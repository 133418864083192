import { handleActions } from 'redux-actions'
import fetch from '../../../services/fetch'
import logger from '../../../services/errorlogger'

export const actionTypes = {
  FLUSH_COUPON: 'FLUSH_COUPON',
  COUPON_LOADING: 'COUPON_LOADING',
  COUPON_SUCCESS: 'COUPON_SUCCESS',
  COUPON_ERROR: 'COUPON_ERROR',
}

export const actions = {
  applyCoupon: (coupon) => async (dispatch, getState) => {
    if (!coupon) {
      return dispatch(actions.createSubscriptionError('Please fill in your coupon'))
    }
    try {
      dispatch({ type: actionTypes.COUPON_LOADING })
      const result = await fetch.get(`/billing/charge/coupon/${coupon}`)
      const { referral, coupon: couponData } = result.data
      dispatch({ type: actionTypes.COUPON_SUCCESS, referral, coupon: couponData })
    } catch (e) {
      console.log('error', e)
      logger.logError(e)
      dispatch({ type: actionTypes.COUPON_ERROR })
    }
  },
  flushCoupon: () => async (dispatch, getState) => {
    dispatch({ type: actionTypes.FLUSH_COUPON })
  },
}

export const getDefaultState = () => ({
  couponLoading: false,
  couponSuccess: false,
  couponError: false,
  coupon: null,
})

export default handleActions(
  {
    [actionTypes.FLUSH_COUPON]: () => getDefaultState(),
    [actionTypes.COUPON_LOADING]: (state) => ({
      ...state,
      couponLoading: true,
      couponError: false,
      couponSuccess: false,
    }),
    [actionTypes.COUPON_SUCCESS]: (state, { coupon, referral }) => {
      return {
        ...state,
        couponLoading: false,
        couponSuccess: true,
        couponError: false,
        coupon,
        referral: referral || state.referral,
      }
    },
    [actionTypes.COUPON_ERROR]: (state) => {
      return { ...state, couponLoading: false, couponSuccess: false, couponError: true }
    },
  },
  getDefaultState()
)
