import { handleActions } from 'redux-actions'

export const actionTypes = {
  TOGGLE_SHOW_UPGRADE_MODAL: 'TOGGLE_SHOW_UPGRADE_MODAL',
  TOGGLE_SHOW_BILLING_MODAL: 'TOGGLE_SHOW_BILLING_MODAL',
}

export const actions = {
  toggleUpgradeModal: (upgradeModalType) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.TOGGLE_SHOW_UPGRADE_MODAL, upgradeModalType })
  },
  toggleBillingModal: (billingModalUserId) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.TOGGLE_SHOW_BILLING_MODAL, billingModalUserId })
  },
}

export const getDefaultState = () => ({
  showUpgradeModal: false,
  upgradeModalType: null,

  showBillingModal: false,
  billingModalUserId: null,
})

export default handleActions(
  {
    [actionTypes.TOGGLE_SHOW_UPGRADE_MODAL]: (state, { upgradeModalType }) => ({
      ...state,
      showUpgradeModal: !state.showUpgradeModal,
      upgradeModalType,
    }),
    [actionTypes.TOGGLE_SHOW_BILLING_MODAL]: (state, { billingModalUserId }) => ({
      ...state,
      showBillingModal: !state.showBillingModal,
      billingModalUserId,
    }),
  },
  getDefaultState()
)
