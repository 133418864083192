import axios from 'axios'
import { getBaseUrl } from '../store/environment'

const BASE_API_URL = `${getBaseUrl()}/api/`

const Instance = axios.create({
  baseURL: BASE_API_URL,
  timeout: 1000000,
})

const getUserId = () => localStorage && localStorage.getItem('userId')
const userToken = () => localStorage && localStorage.getItem('token')
// const userAuthToken = () => localStorage.getItem('authToken');

Instance.interceptors.request.use(
  (config) => {
    config.headers['userid'] = getUserId()
    config.headers['usertoken'] = userToken()
    // config.headers['Autorization'] = `Bearer ${userAuthToken()}`;
    // console.log('attached header', getToken());
    return config
  },
  (error) => {
    Promise.reject(error)
  }
)

export default Instance
