import React, { Component as C } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose } from 'redux'
import moment from 'moment'

import { actions as SettingsReducer } from '../store/SettingsReducer'

class PrivateRoute extends C {
  isAuthenticated = () => {
    const { loading, userId } = this.props.settings
    // if u have a userId, you can view any id you want
    return !loading && userId
  }

  isVerified = () => {
    const { userId, emailVerified } = this.props.settings
    return userId && emailVerified
  }

  isTrialCompleted = () => {
    const { isTrial, permissions } = this.props.settings
    // console.log('IS TRIAL ACTIVE', permissions.isTrialActive);
    return isTrial && !permissions.isTrialActive
  }

  hasPurchased = () => {
    const { userId, permissions } = this.props.settings
    return userId && permissions.hasPurchased
  }

  isActive = () => {
    const { userId, isActive } = this.props.settings
    return userId && isActive
  }

  isCancelled = () => {
    const { userId, isCancelled } = this.props.settings
    return userId && isCancelled
  }

  isDelinquent = () => {
    const { userId, firstFailedCharge } = this.props.settings
    if (!firstFailedCharge) return false

    const allowedUnpaidDays = 10
    const softDelinquentDaysLeft = moment(firstFailedCharge)
      .add(allowedUnpaidDays + 1, 'days')
      .diff(new Date().getTime(), 'days')

    return userId && softDelinquentDaysLeft < 0
  }

  renderRoute = (Component) => (props) => {
    if (!this.isAuthenticated()) {
      return <Redirect to={{ pathname: '/login/', state: { from: props.location } }} />
    }
    if (!this.isVerified()) {
      return <Redirect to={{ pathname: '/verify/', state: { from: props.location } }} />
    }
    if (
      this.isCancelled() &&
      !props.location.pathname.includes('/app/account') &&
      !props.location.pathname.includes('/app/pricing')
    ) {
      return <Redirect to={{ pathname: '/app/pricing/', state: { from: props.location } }} />
    }
    // if (this.isTrialCompleted() || !this.hasPurchased()) {
    //     return <Redirect to={{ pathname: "/app/pricing/", state: { from: props.location } }} />;
    // }
    if (
      this.isDelinquent() &&
      !props.location.pathname.includes('/app/account') &&
      !props.location.pathname.includes('/app/pricing')
    ) {
      return (
        <Redirect to={{ pathname: '/app/account/billing/', state: { from: props.location } }} />
      )
    }
    if (!this.isActive()) {
      return <Redirect to={{ pathname: '/inactive/', state: { from: props.location } }} />
    }

    return <Component {...props} />
  }

  render() {
    const { component: Component, ...rest } = this.props

    return <Route {...rest} render={this.renderRoute(Component)} />
  }
}

const mapStateToProps = (state) => state

const mapDispatchToProps = {
  ...SettingsReducer,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(PrivateRoute)
