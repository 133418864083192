import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose } from 'redux'

import { Modal, Button, Typography } from 'antd'

import { RocketFilled } from '@ant-design/icons'

import history from '../../history'

import { actions as ModalActions } from '../../store/GlobalModalsReducer'

const { Title } = Typography

const copy = {
  projects: {
    title: `You've Reached Max Projects For Your Plan`,
    body: 'Upgrade now to get more projects for your account.',
  },
  credits: {
    title: `You've Reached Max Credits For This Month`,
    body: 'Upgrade now to get more credits for your account every month.',
  },
  altfinder: {
    title: `Upgrade to use Alternatives Finder`,
    body: 'Alternatives Finder Is Not Enabled For Your Plan',
  },
  cadence: {
    title: `Please Upgrade to Check Links More Often`,
    body: 'You can only check links monthly on the free plan',
  },
  sitemap: {
    title: `You Can Only Provide Your Sitemap on a Paid Plan`,
    body: 'Upgrade now to pick which pages of your site you want to crawl.',
  },
}

class UpgradeModal extends Component {
  onClose = () => {
    this.props.toggleUpgradeModal()
  }

  onUpgrade = () => {
    this.onClose()
    history.push('/app/pricing/')
  }

  render() {
    const { globalModals } = this.props
    const { showUpgradeModal, upgradeModalType } = globalModals
    const currentCopy = copy[upgradeModalType]
    if (!currentCopy) return null
    const { title, body } = currentCopy
    return (
      <Modal
        title="Basic Modal"
        className="core-optin-modal"
        visible={showUpgradeModal}
        onCancel={this.onClose}
        okButtonProps={{ disabled: true }}
        // cancelButtonProps={{ disabled: true }}
        footer={null}
        header={null}
        width={600}
        style={{ marginTop: '20vh' }}
      >
        <Title level={4} style={{ marginBottom: 10, marginTop: 20 }}>
          {title}
        </Title>
        <div style={{ width: '60%', margin: '10px auto 0 auto', fontSize: '15px' }}>
          Oh no, it looks like you've hit an account limit. <br />
          {body}
          <br />
          <Button
            style={{ marginTop: 20 }}
            className="core-optin-cta"
            onClick={this.onUpgrade}
            htmlType="submit"
          >
            <RocketFilled /> Open Pricing
          </Button>
        </div>
      </Modal>
    )
  }
}

const mapStateToProps = (state) => state

const mapDispatchToProps = {
  ...ModalActions,
}

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(UpgradeModal)
