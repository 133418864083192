import { createLogger } from 'redux-logger'
import { createStore, applyMiddleware, combineReducers } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'
import * as Sentry from '@sentry/react'

import crashReporter from './middlewares/crashReporter'

import SettingsReducer from './SettingsReducer'
import SitesReducer from './SitesReducer'
import JobsReducer from './JobsReducer'
import AnalyticsReducer from './AnalyticsReducer'
import ProductsReducer from './Products/ProductsReducer'
import MonitorReducer from './MonitorReducer'
import BillingReducer from './BillingReducer'
import GlobalModalsReducer from './GlobalModalsReducer'

import NotificationsReducer from '../modules/Notifications/reducer'
import PaymentConfirmationReducer from '../modules/Checkout/PaymentConfirmation/reducer'
import BillingDetailsReducer from '../modules/Checkout/BillingDetails/reducer'
import CouponReducer from '../modules/Checkout/Coupon/reducer'
import PaymentMethodsReducer from '../modules/Checkout/PaymentMethods/reducer'
import CheckoutReducer from '../modules/Checkout/Checkout/reducer'

const rootReducer = () =>
  combineReducers({
    // Checkout reducers
    paymentConfirmationReducer: PaymentConfirmationReducer,
    billingDetailsReducer: BillingDetailsReducer,
    couponReducer: CouponReducer,
    paymentMethodsReducer: PaymentMethodsReducer,
    checkoutReducer: CheckoutReducer,

    settings: SettingsReducer,
    sites: SitesReducer,
    jobs: JobsReducer,
    notifications: NotificationsReducer,
    analytics: AnalyticsReducer,
    products: ProductsReducer,
    monitor: MonitorReducer,
    billing: BillingReducer,
    globalModals: GlobalModalsReducer,
  })

const createReduxStore = (makeRootReducer = rootReducer, additionalMiddlewares = []) => {
  const middleware = [
    thunk,
    process.env.NODE_ENV === 'development' && createLogger({ collapsed: true }),
    crashReporter,
    ...additionalMiddlewares,
  ].filter((mw) => !!mw)

  const initialState = {}

  const sentryReduxEnhancer = Sentry.createReduxEnhancer({
    stateTransformer: (state) => {
      const transformedState = {
        ...state,
        notifications: null,
        sites: null,
        jobs: null,
        notifications: null,
        analytics: null,
        products: null,
        globalModals: null,
      }
      return transformedState
    },
  })

  const composeEnhancers = composeWithDevTools([sentryReduxEnhancer])

  const store = createStore(
    makeRootReducer(),
    initialState,
    composeEnhancers(applyMiddleware(...middleware))
  )

  return store
}

export default createReduxStore
