import * as Sentry from '@sentry/browser'
import { Integrations } from '@sentry/tracing'
import { message } from 'antd'
import fire from './fire'
import history from '../history'

const isProduction = process.env.NODE_ENV === 'production'

const logger = {
  init: () => {
    if (!isProduction) return null
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_KEY_2,
      normalizeDepth: 5,
      maxBreadcrumbs: 20,
      integrations: [new Integrations.BrowserTracing()],
      tracesSampleRate: 1.0,
      // beforeSend(event, hint) {
      //     try {
      //         const isNonErrorException =
      //         event.exception.values[0].value.startsWith('Non-Error exception captured') ||
      //         hint.originalException['message'].startsWith('Non-Error exception captured');

      //         if (isNonErrorException) {
      //             // We want to ignore those kind of errors
      //             return null;
      //         }
      //         return event;
      //     } catch (e) {
      //         console.log('error in sentry scaffolding', e);
      //         return event;
      //     }
      // }
    })
    Sentry.configureScope(function (scope) {
      scope.setTag('env', 'frontend')
    })
  },
  logError: (err) => {
    if (!isProduction) return null
    // debugger;
    Sentry.captureException(err)
  },
  setUser: (email, userId) => {
    if (!isProduction || !email) return null
    Sentry.configureScope(function (scope) {
      scope.setUser({ email, userId })
    })
  },
  handleError: async (err, showMessage = true) => {
    try {
      // debugger;
      // if (err.response.status === 401) {
      if (err.response && err.response.status === 401) {
        await fire.auth().signOut()
        localStorage && localStorage.removeItem('userId')
        localStorage && localStorage.removeItem('token')
        history.push('/login')
        logger.logError(err)
        return message.info(`Session Expired. Please log in again`)
      }
      console.log('Error Occured')
      console.log(err)
      showMessage && message.warn(`Error: ${err.message}`, 10)
      logger.logError(err)
    } catch (e) {
      console.log('Sentry Error Reporting Failed')
      console.log(e)
      logger.logError(e)
    }
  },
}

export default logger
