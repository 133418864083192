import firebase from 'firebase'

// const isDevelopment = process.env.NODE_ENV === 'development';
const isDevelopment = false //always using prod

const firebaseConfigStaging = {
  apiKey: process.env.REACT_APP_FIREBASE_1_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_1_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_1_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_1_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_1_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_1_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_1_APP_ID,
}

// console.log(process.env);
const firebaseConfigProduction = {
  apiKey: process.env.REACT_APP_FIREBASE_2_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_2_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_2_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_2_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_2_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_2_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_2_APP_ID,
}

const fire = firebase.initializeApp(
  isDevelopment ? firebaseConfigStaging : firebaseConfigProduction
)

export default fire
