import cookies from 'js-cookie'
import queryString from 'query-string'

import logger from '../services/errorlogger'

export const getStoredProps = () => {
  try {
    const { search } = window.location
    const parsed = queryString.parse(search)
    let { inviteId, siteUrl, campaign, analyticsId, source } = parsed
    if (!campaign) {
      if (localStorage && localStorage.getItem('campaign')) {
        campaign = localStorage.getItem('campaign')
      } else {
        const cookie = cookies.get('campaign')
        if (cookie) {
          campaign = cookie
        }
      }
    }

    if (!analyticsId) {
      if (localStorage && localStorage.getItem('analyticsId')) {
        analyticsId = localStorage.getItem('analyticsId')
      } else {
        const cookie = cookies.get('analyticsId')
        if (cookie) {
          analyticsId = cookie
        }
      }
    }
    return {
      inviteId: inviteId || '',
      siteUrl: siteUrl || '',
      campaign: campaign || '',
      analyticsId: analyticsId || '',
      source: source || '',
    }
  } catch (e) {
    console.log(e)
    logger.logError(e)
    return {
      inviteId: '',
      siteUrl: '',
      campaign: '',
      analyticsId: '',
      source: '',
    }
  }
}
