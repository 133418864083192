import { handleActions } from 'redux-actions'
import fetch from '../../../services/fetch'
import history from '../../../history'
import { getStripeKey } from '../../../store/environment'
import tracker from '../../../services/eventTracking'
import logger from '../../../services/errorlogger'
import { message } from 'antd'

// import { actions as SettingsActions } from './SettingsReducer'

export const actionTypes = {
  FLUSH_CHECKOUT: 'FLUSH_CHECKOUT',
  CREATE_SUBSCRIPTION_LOADING: 'CREATE_SUBSCRIPTION_LOADING',
  CREATE_SUBSCRIPTION_SUCCESS: 'CREATE_SUBSCRIPTION_SUCCESS',
  CREATE_SUBSCRIPTION_ERROR: 'CREATE_SUBSCRIPTION_ERROR',
  CONFIRM_SUBSCRIPTION_LOADING: 'CONFIRM_SUBSCRIPTION_LOADING',
  CONFIRM_SUBSCRIPTION_SUCCESS: 'CONFIRM_SUBSCRIPTION_SUCCESS',
  CONFIRM_SUBSCRIPTION_ERROR: 'CONFIRM_SUBSCRIPTION_ERROR',
  SET_REFERRAL_ID: 'SET_REFERRAL_ID',
}

export const actions = {
  flushCheckout: () => async (dispatch, getState) => dispatch({ type: actionTypes.FLUSH_CHECKOUT }),
  // is this even called?
  createSubscription: (paymentMethodId, chargeDetails) => async (dispatch, getState) => {
    if (!paymentMethodId || !chargeDetails) {
      return dispatch(actions.createSubscriptionError('Issue With Payment Provider'))
    }
    try {
      const { amount, description, plan, planId, name, isYearly, coupon, price, billingDetails } =
        chargeDetails
      const { settings, billing } = getState()
      const payload = {
        description,
        name,
        paymentMethodId,
        currency: 'USD',
        amount,
        planId,
        coupon,
        metadata: {
          plan: plan.toLowerCase(),
          referral: billing.referral,
          isYearly,
        },
        billingDetails: billingDetails || {},
        email: settings.email,
      }
      dispatch({ type: actionTypes.CREATE_SUBSCRIPTION_LOADING })
      const result = await fetch.put(`/billing/charge/`, payload)
      if (result.data) {
        console.log(result.data)
        return null
      }
      dispatch(actions.confirmSubscription())
      dispatch(actions.createSubscriptionSuccess(result))
      tracker.purchase(price)
    } catch (e) {
      console.log('error', e)
      logger.logError(e)
      dispatch(
        actions.createSubscriptionError('There has been an issue while charging your card', e)
      )
    }
  },
  // is this even called?
  createSubscription2: (details) => async (dispatch, getState) => {
    if (!details) {
      return dispatch(actions.createSubscriptionError('Issue With Payment Provider'))
    }
    try {
      const { amount, description, plan, planId, name, isYearly, coupon, price, billingDetails } =
        details
      const { settings, billing } = getState()
      const payload = {
        description,
        name,
        currency: 'USD',
        amount,
        planId,
        coupon,
        metadata: {
          plan: plan.toLowerCase(),
          referral: billing.referral,
          isYearly,
        },
        billingDetails: billingDetails || {},
        email: settings.email,
      }
      dispatch({ type: actionTypes.CREATE_SUBSCRIPTION_LOADING })
      const result = await fetch.put(`/billing/charge/`, payload)
      if (result.data) {
        console.log(result.data)
        return null
      }
      dispatch(actions.confirmSubscription())
      dispatch(actions.createSubscriptionSuccess(result))
      tracker.purchase(price)
    } catch (e) {
      console.log('error', e)
      logger.logError(e)
      dispatch(
        actions.createSubscriptionError('There has been an issue while charging your card', e)
      )
    }
  },
  upgradeUser: (chargeDetails) => async (dispatch, getState) => {
    try {
      if (!chargeDetails) {
        return dispatch(actions.createSubscriptionError('Issue With Payment Provider'))
      }
      const { amount, description, plan, planId, isYearly, coupon, price } = chargeDetails
      const { settings } = getState()
      const { email, name, referral } = settings
      const payload = {
        description,
        name,
        source: 'stripe',
        currency: 'USD',
        amount,
        planId,
        coupon,
        metadata: {
          plan: plan.toLowerCase(),
          isYearly,
          referral,
        },
        email,
      }
      dispatch({ type: actionTypes.CREATE_SUBSCRIPTION_LOADING })
      const result = await fetch.post(`/billing/charge/`, payload)
      if (result.data) {
        console.log(result.data)
        return null
      }
      dispatch(actions.confirmSubscription())
      dispatch(actions.createSubscriptionSuccess(result))
      tracker.purchase(price)
    } catch (e) {
      console.log('error', e)
      logger.logError(e)
      dispatch(actions.createSubscriptionError(e.message))
    }
  },
  createSubscriptionLoading: (data) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.CREATE_SUBSCRIPTION_LOADING })
  },
  createSubscriptionSuccess: (data) => async (dispatch, getState) => {
    const invoiceUrl = data ? data.data.invoiceUrl : null
    dispatch({ type: actionTypes.CREATE_SUBSCRIPTION_SUCCESS, invoiceUrl })
  },
  createSubscriptionError: (data) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.CREATE_SUBSCRIPTION_ERROR })
    message.error(data)
  },
  confirmSubscription: () => async (dispatch, getState) => {
    try {
      dispatch({ type: actionTypes.CONFIRM_SUBSCRIPTION_LOADING })
      const { data: result } = await fetch.get(`/billing/charge/confirm/`)

      if (result.chargeFinalized) {
        return dispatch({ type: actionTypes.CONFIRM_SUBSCRIPTION_SUCCESS })
      }
      return setTimeout(() => dispatch(actions.confirmSubscription()), 2500)
    } catch (e) {
      console.log('error', e)
      logger.logError(e)
      dispatch({ type: actionTypes.CONFIRM_SUBSCRIPTION_ERROR })
    }
  },
  chargePaypal: () => async (dispatch, getState) => {
    try {
      // we do not support paypal now
      return
      dispatch({ type: actionTypes.CONFIRM_SUBSCRIPTION_LOADING })
      const result = await fetch.get(`/billing/charge/pp/`)
      dispatch({ type: actionTypes.CONFIRM_SUBSCRIPTION_SUCCESS })
    } catch (e) {
      console.log('error', e)
      logger.logError(e)
      dispatch({ type: actionTypes.CONFIRM_SUBSCRIPTION_ERROR })
    }
  },
  setReferralId: (referralId) => async (dispatch, getState) =>
    dispatch({ type: actionTypes.SET_REFERRAL_ID, referralId }),
}

export const getDefaultState = () => ({
  createSubscriptionLoading: false,
  createSubscriptionSuccess: false,
  createSubscriptionError: false,

  updateSubscriptionLoading: false,
  updateSubscriptionSuccess: false,
  updateSubscriptionError: false,

  confirmSubscriptionLoading: false,
  confirmSubscriptionSuccess: false,
  confirmSubscriptionError: false,

  referralId: null,

  invoiceUrl: null,

  key: getStripeKey(),
})

export default handleActions(
  {
    [actionTypes.FLUSH_CHECKOUT]: () => getDefaultState(),
    [actionTypes.SET_REFERRAL_ID]: (state, { referralId }) => ({ ...state, referralId }),
    [actionTypes.CREATE_SUBSCRIPTION_LOADING]: (state) => ({
      ...state,
      createSubscriptionLoading: true,
      createSubscriptionSuccess: false,
      createSubscriptionError: false,
    }),
    [actionTypes.CREATE_SUBSCRIPTION_SUCCESS]: (state, { invoiceUrl }) => ({
      ...state,
      createSubscriptionLoading: false,
      createSubscriptionSuccess: true,
      createSubscriptionError: false,
      invoiceUrl,
    }),
    [actionTypes.CREATE_SUBSCRIPTION_ERROR]: (state) => ({
      ...state,
      createSubscriptionLoading: false,
      createSubscriptionSuccess: false,
      createSubscriptionError: true,
    }),
    [actionTypes.CONFIRM_SUBSCRIPTION_LOADING]: (state) => ({
      ...state,
      confirmSubscriptionLoading: true,
      confirmSubscriptionError: false,
      confirmSubscriptionSuccess: false,
    }),
    [actionTypes.CONFIRM_SUBSCRIPTION_SUCCESS]: (state) => ({
      ...state,
      confirmSubscriptionLoading: false,
      confirmSubscriptionError: false,
      confirmSubscriptionSuccess: true,
    }),
    [actionTypes.CONFIRM_SUBSCRIPTION_ERROR]: (state) => ({
      ...state,
      confirmSubscriptionLoading: false,
      confirmSubscriptionError: true,
      confirmSubscriptionSuccess: false,
    }),
  },
  getDefaultState()
)
