import { handleActions } from 'redux-actions'
import fetch from '../../../services/fetch'
import history from '../../../history'
import logger from '../../../services/errorlogger'

export const actionTypes = {
  FLUSH_CONFIRM_SUBSCRIPTION: 'FLUSH_CONFIRM_SUBSCRIPTION',
  CONFIRM_SUBSCRIPTION_LOADING: 'CONFIRM_SUBSCRIPTION_LOADING',
  CONFIRM_SUBSCRIPTION_SUCCESS: 'CONFIRM_SUBSCRIPTION_SUCCESS',
  CONFIRM_SUBSCRIPTION_ERROR: 'CONFIRM_SUBSCRIPTION_ERROR',
}

let timer

export const actions = {
  flushConfirmCharge: () => async (dispatch, getState) => {
    dispatch({ type: actionTypes.FLUSH_CONFIRM_SUBSCRIPTION })
    clearTimeout(timer)
  },
  confirmCharge: () => async (dispatch, getState) => {
    try {
      dispatch({ type: actionTypes.CONFIRM_SUBSCRIPTION_LOADING })
      const { data: result } = await fetch.get(`/billing/charge/confirm/`)
      console.log('result', result)
      if (result.chargeFinalized) {
        return dispatch(actions.confirmChargeSuccess())
      }
      timer = setTimeout(() => dispatch(actions.confirmCharge()), 2500)
    } catch (e) {
      console.log('confirm charge error', e)
      logger.logError(e)
      dispatch(actions.confirmChargeError())
    }
  },
  confirmChargeSuccess: (data) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.CONFIRM_SUBSCRIPTION_SUCCESS })
    history.push('/app/payment/success/')
  },
  confirmChargeError: (data) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.CONFIRM_SUBSCRIPTION_ERROR })
    console.log(data)
    console.log('Payment Error')
    clearTimeout(timer)
  },
}

export const getDefaultState = () => ({
  confirmSubscriptionLoading: false,
  confirmSubscriptionSuccess: false,
  confirmSubscriptionError: false,
})

export default handleActions(
  {
    [actionTypes.FLUSH_CONFIRM_SUBSCRIPTION]: () => getDefaultState(),
    [actionTypes.CONFIRM_SUBSCRIPTION_LOADING]: (state) => ({
      ...state,
      confirmSubscriptionLoading: true,
      confirmSubscriptionError: false,
      confirmSubscriptionSuccess: false,
    }),
    [actionTypes.CONFIRM_SUBSCRIPTION_SUCCESS]: (state) => ({
      ...state,
      confirmSubscriptionLoading: false,
      confirmSubscriptionError: false,
      confirmSubscriptionSuccess: true,
    }),
    [actionTypes.CONFIRM_SUBSCRIPTION_ERROR]: (state) => ({
      ...state,
      confirmSubscriptionLoading: false,
      confirmSubscriptionError: true,
      confirmSubscriptionSuccess: false,
    }),
  },
  getDefaultState()
)
