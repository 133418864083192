import { handleActions } from 'redux-actions'
import fetch from '../../services/fetch'
import _ from 'lodash'
import logger from '../../services/errorlogger'

export const actionTypes = {
  FETCH_NOTIFICATIONS_LOADING: 'FETCH_NOTIFICATIONS_LOADING',
  FETCH_NOTIFICATIONS_DONE: 'FETCH_NOTIFICATIONS_DONE',
  NOTIFICATIONS_UPDATE: 'NOTIFICATIONS_UPDATE',
}

export const actions = {
  fetchNotifications: () => async (dispatch, getState) => {
    try {
      const { loading } = getState().notifications
      const { userId, emailVerified } = getState().settings
      if (loading || !userId || !emailVerified) return

      dispatch({ type: actionTypes.FETCH_NOTIFICATIONS_LOADING })

      const { data: notifications } = await fetch.get(`/notifications/`)
      dispatch({ type: actionTypes.FETCH_NOTIFICATIONS_DONE, notifications })
    } catch (e) {
      logger.handleError(e)
    }
  },
  markNotificationSeen: (notificationId) => async (dispatch, getState) => {
    try {
      await fetch.patch(`/notifications/${notificationId}`)
      dispatch(actions.fetchNotifications())
    } catch (e) {
      logger.handleError(e)
    }
  },
  markAllNotificationsSeen: () => async (dispatch, getState) => {
    try {
      await fetch.patch(`/notifications/`)
      dispatch(actions.fetchNotifications())
    } catch (e) {
      logger.handleError(e)
    }
  },
  removeSiteNotifications: (siteId) => async (dispatch, getState) => {
    try {
      await fetch.delete(`/notifications/`, { siteId })
      dispatch(actions.fetchNotifications())
    } catch (e) {
      logger.handleError(e)
    }
  },
}

export const getDefaultState = () => ({
  notifications: [],
})

export default handleActions(
  {
    [actionTypes.FETCH_NOTIFICATIONS_LOADING]: (state) => ({ ...state, loading: true }),
    [actionTypes.FETCH_NOTIFICATIONS_DONE]: (state, { notifications }) => {
      if (!notifications || Object.keys(notifications).length === 0)
        return { ...state, notifications: [], loading: false }
      const nextNotifications = _.flatten(
        Object.keys(notifications).map((id) => ({ ...notifications[id], notificationId: id }))
      ).reverse()
      return { ...state, loading: false, notifications: nextNotifications }
    },
    [actionTypes.NOTIFICATIONS_UPDATE]: (state, { notification, key }) => {
      const nextNotifications = [...state.notifications, notification]
      return { ...state, loading: false, notifications: nextNotifications }
    },
  },
  getDefaultState()
)
