import React, { Component } from 'react'
import { Layout } from 'antd'

const { Footer } = Layout

class FooterComponent extends Component {
  openCrisp = () => {
    const $crisp = window.$crisp
    if ($crisp && $crisp.is('chat:closed')) {
      window.$crisp.push(['do', 'chat:toggle'])
    }
  }

  render() {
    const menu = [
      { name: '📚 Help Articles', url: 'https://amzwatcher.com/knowledge-base' },
      { name: '💬 Chat With Us', action: this.openCrisp },
      { name: '💳 Pricing', url: 'https://app.amzwatcher.com/app/pricing' },
      { name: '📘 Blog', url: 'https://amzwatcher.com/blog' },
      { name: '🛠 Free Tools', url: 'https://amzwatcher.com/tools' },
      { name: '🤑 Affiliate Program', url: 'https://amzwatcher.com/affiliate-program/' },
    ]
    return (
      <Footer
        style={{ textAlign: 'center', background: '#f0f2f5', color: '#676c71', fontSize: 14.5 }}
      >
        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 20 }}>
          {menu.map((x, index) => (
            <a
              onClick={x.action && x.action}
              key={x.name}
              href={x.url && x.url}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                marginRight: index === menu.length - 1 ? 0 : 25,
                borderBottom: '1px solid #676c71',
                color: '#676c71',
              }}
            >
              {x.name}
            </a>
          ))}
        </div>
        <div>AMZ Watcher. Crafted with Love in Europe</div>
      </Footer>
    )
  }
}

export default FooterComponent
