import { handleActions } from 'redux-actions'
import { message } from 'antd'
import fetch from '../../../services/fetch'
import logger from '../../../services/errorlogger'

export const actionTypes = {
  FLUSH_PAYMENT_METHODS: 'FLUSH_PAYMENT_METHODS',
  FETCH_PAYMENT_METHODS_LOADING: 'FETCH_PAYMENT_METHODS_LOADING',
  FETCH_PAYMENT_METHODS_SUCCESS: 'FETCH_PAYMENT_METHODS_SUCCESS',
  FETCH_PAYMENT_METHODS_ERROR: 'FETCH_PAYMENT_METHODS_ERROR',
  FETCH_PRIMARY_PAYMENT_METHODS_LOADING: 'FETCH_PRIMARY_PAYMENT_METHODS_LOADING',
  FETCH_PRIMARY_PAYMENT_METHODS_SUCCESS: 'FETCH_PRIMARY_PAYMENT_METHODS_SUCCESS',
  FETCH_PRIMARY_PAYMENT_METHODS_ERROR: 'FETCH_PRIMARY_PAYMENT_METHODS_ERROR',
  ADD_PAYMENT_METHOD_LOADING: 'ADD_PAYMENT_METHOD_LOADING',
  ADD_PAYMENT_METHOD_ERROR: 'ADD_PAYMENT_METHOD_ERROR',
  ADD_PAYMENT_METHOD_SUCCESS: 'ADD_PAYMENT_METHOD_SUCCESS',
  FLUSH_ADD_PAYMENT_METHOD: 'FLUSH_ADD_PAYMENT_METHOD',
  SET_PRIMARY_PAYMENT_METHOD_LOADING: 'SET_PRIMARY_PAYMENT_METHOD_LOADING',
  SET_PRIMARY_PAYMENT_METHOD_ERROR: 'SET_PRIMARY_PAYMENT_METHOD_ERROR',
  SET_PRIMARY_PAYMENT_METHOD_SUCCESS: 'SET_PRIMARY_PAYMENT_METHOD_SUCCESS',
  DELETE_PAYMENT_METHOD_LOADING: 'DELETE_PAYMENT_METHOD_LOADING',
  DELETE_PAYMENT_METHOD_ERROR: 'DELETE_PAYMENT_METHOD_ERROR',
  DELETE_PAYMENT_METHOD_SUCCESS: 'DELETE_PAYMENT_METHOD_SUCCESS',
}

export const actions = {
  flushPaymentMethods: () => async (dispatch, getState) => {
    dispatch({ type: actionTypes.FLUSH_PAYMENT_METHODS })
  },
  fetchPaymentMethods: () => async (dispatch, getState) => {
    try {
      dispatch({ type: actionTypes.FETCH_PAYMENT_METHODS_LOADING })
      const result = await fetch.get(`/billing/details/payment-methods`)
      const { paymentMethods } = result.data
      dispatch({ type: actionTypes.FETCH_PAYMENT_METHODS_SUCCESS, paymentMethods })
    } catch (e) {
      console.log('error', e)
      logger.logError(e)
      dispatch({ type: actionTypes.FETCH_PAYMENT_METHODS_ERROR })
    }
  },
  addPaymentMethod: (paymentMethodId) => async (dispatch, getState) => {
    try {
      dispatch({ type: actionTypes.ADD_PAYMENT_METHOD_LOADING })
      const result = await fetch.put(`/billing/details/payment-methods`, { paymentMethodId })
      dispatch({ type: actionTypes.ADD_PAYMENT_METHOD_SUCCESS })
      dispatch(actions.fetchPaymentMethods())
      message.success('Payment method has been added')
    } catch (e) {
      console.log('error', e)
      logger.logError(e)
      dispatch({ type: actionTypes.ADD_PAYMENT_METHOD_ERROR })
    }
  },
  flushAddPaymentMethod: () => async (dispatch, getState) => {
    dispatch({ type: actionTypes.FLUSH_ADD_PAYMENT_METHOD })
  },
  setPrimaryPaymentMethod: (paymentMethodId) => async (dispatch, getState) => {
    try {
      dispatch({ type: actionTypes.SET_PRIMARY_PAYMENT_METHOD_LOADING })
      const result = await fetch.post(`/billing/details/payment-methods/${paymentMethodId}`)
      dispatch({ type: actionTypes.SET_PRIMARY_PAYMENT_METHOD_SUCCESS })
      dispatch(actions.fetchPaymentMethods())
      message.success('Payment method has been set as primary')
    } catch (e) {
      console.log('error', e)
      logger.logError(e)
      dispatch({ type: actionTypes.SET_PRIMARY_PAYMENT_METHOD_ERROR })
      message.error('There has been a problem making this paymentMethod primary')
    }
  },
  deletePaymentMethod: (paymentMethodId) => async (dispatch, getState) => {
    try {
      dispatch({ type: actionTypes.DELETE_PAYMENT_METHOD_LOADING })
      const result = await fetch.delete(`/billing/details/payment-methods/${paymentMethodId}`)
      dispatch({ type: actionTypes.DELETE_PAYMENT_METHOD_SUCCESS })
      dispatch(actions.fetchPaymentMethods())
      message.success('Payment method has been removed')
    } catch (e) {
      console.log('error', e)
      logger.logError(e)
      dispatch({ type: actionTypes.DELETE_PAYMENT_METHOD_ERROR })
    }
  },
}

export const getDefaultState = () => ({
  paymentMethods: null,
  paymentMethodsLoading: false,
  paymentMethodsError: false,
  paymentMethodsSuccess: false,

  // TODO: make sure that active payment method
  // is always fetched separately from the rest of billing
  // move all dependencies on active payment method to here
  activePaymentMethod: null,
  activePaymentMethodsLoading: false,
  activePaymentMethodsError: false,
  activePaymentMethodsSuccess: false,

  addPaymentMethodLoading: false,
  addPaymentMethodError: false,
  addPaymentMethodSuccess: false,

  setPrimaryPaymentMethodLoading: false,
  setPrimaryPaymentMethodError: false,
  setPrimaryPaymentMethodSuccess: false,

  deletePaymentMethodLoading: false,
  deletePaymentMethodError: false,
  deletePaymentMethodSuccess: false,
})

export default handleActions(
  {
    [actionTypes.FLUSH_PAYMENT_METHODS]: () => getDefaultState(),
    [actionTypes.FETCH_PAYMENT_METHODS_LOADING]: (state) => ({
      ...state,
      paymentMethodsLoading: true,
      paymentMethodsError: false,
      paymentMethodsSuccess: false,
    }),
    [actionTypes.FETCH_PAYMENT_METHODS_SUCCESS]: (state, { paymentMethods }) => {
      return {
        ...state,
        paymentMethodsLoading: false,
        paymentMethodsError: false,
        paymentMethodsSuccess: true,
        paymentMethods,
      }
    },
    [actionTypes.FETCH_PAYMENT_METHODS_ERROR]: (state) => ({
      ...state,
      paymentMethodsLoading: false,
      paymentMethodsError: true,
      paymentMethodsSuccess: false,
    }),
    [actionTypes.FLUSH_ADD_PAYMENT_METHOD]: (state) => {
      return {
        ...state,
        addPaymentMethodLoading: false,
        addPaymentMethodError: false,
        addPaymentMethodSuccess: false,
      }
    },
    [actionTypes.ADD_PAYMENT_METHOD_LOADING]: (state) => {
      return {
        ...state,
        addPaymentMethodLoading: true,
        addPaymentMethodError: false,
        addPaymentMethodSuccess: false,
      }
    },
    [actionTypes.ADD_PAYMENT_METHOD_ERROR]: (state) => {
      return {
        ...state,
        addPaymentMethodLoading: false,
        addPaymentMethodError: true,
        addPaymentMethodSuccess: false,
      }
    },
    [actionTypes.ADD_PAYMENT_METHOD_SUCCESS]: (state) => {
      return {
        ...state,
        addPaymentMethodLoading: false,
        addPaymentMethodError: false,
        addPaymentMethodSuccess: true,
      }
    },
    [actionTypes.DELETE_PAYMENT_METHOD_LOADING]: (state) => {
      return {
        ...state,
        deletePaymentMethodLoading: true,
        deletePaymentMethodError: false,
        deletePaymentMethodSuccess: false,
      }
    },
    [actionTypes.DELETE_PAYMENT_METHOD_ERROR]: (state) => {
      return {
        ...state,
        deletePaymentMethodLoading: false,
        deletePaymentMethodError: true,
        deletePaymentMethodSuccess: false,
      }
    },
    [actionTypes.DELETE_PAYMENT_METHOD_SUCCESS]: (state) => {
      return {
        ...state,
        deletePaymentMethodLoading: false,
        deletePaymentMethodError: false,
        deletePaymentMethodSuccess: true,
      }
    },
    [actionTypes.SET_PRIMARY_PAYMENT_METHOD_LOADING]: (state) => {
      return {
        ...state,
        deletePaymentMethodLoading: true,
        deletePaymentMethodError: false,
        deletePaymentMethodSuccess: false,
      }
    },
    [actionTypes.SET_PRIMARY_PAYMENT_METHOD_ERROR]: (state) => {
      return {
        ...state,
        deletePaymentMethodLoading: false,
        deletePaymentMethodError: true,
        deletePaymentMethodSuccess: false,
      }
    },
    [actionTypes.SET_PRIMARY_PAYMENT_METHOD_SUCCESS]: (state) => {
      return {
        ...state,
        deletePaymentMethodLoading: false,
        deletePaymentMethodError: false,
        deletePaymentMethodSuccess: true,
      }
    },
  },
  getDefaultState()
)
