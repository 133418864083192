module.exports = {
  'Luxury Beauty': 0.1,
  'Amazon Coins': 0.1,
  Furniture: 0.03,
  Home: 0.03,
  'Home Improvement': 0.03,
  'Lawn & Garden': 0.03,
  'Pets Products': 0.03,
  Pantry: 0.03,
  Headphones: 0.03,
  Beauty: 0.03,
  'Musical Instruments': 0.03,
  'Business & Industrial Supplies': 0.03,
  Outdoors: 0.03,
  Tools: 0.03,
  'Digital Music': 0.05,
  Grocery: 0.01,
  'Physical Music': 0.05,
  Handmade: 0.05,
  'Digital Videos': 0.05,
  'Physical Books': 0.045,
  'Health & Personal Care': 0.01,
  Sports: 0.03,
  Kitchen: 0.045,
  Automotive: 0.045,
  'Baby Products': 0.03,
  'Amazon Fire Tablet Devices': 0.04,
  'Amazon Kindle Devices': 0.04,
  "Amazon Fashion Women's": 0.04,
  "Men's & Kids Private Label": 0.04,
  Apparel: 0.04,
  'Amazon Cloud Cam Devices': 0.04,
  'Fire TV Edition Smart TVs': 0.04,
  'Amazon Fire TV Devices': 0.04,
  'Amazon Echo Devices': 0.04,
  'Ring Devices': 0.04,
  Watches: 0.04,
  Jewelry: 0.04,
  Luggage: 0.04,
  Shoes: 0.04,
  'Handbags & Accessories': 0.04,
  'Amazon Fresh': 0.01,
  Toys: 0.03,
  PC: 0.025,
  'PC Components': 0.025,
  'DVD & Blu-Ray': 0.025,
  Televisions: 0.02,
  'Digital Video Games': 0.02,
  videogames: 0.02,
  'Physical Video Games & Video Game Consoles': 0.01,
  'Gift Cards': 0,
  'Wireless Service Plans': 0,
  'Alcoholic Beverages': 0,
  'Digital Kindle Products purchased as a subscription': 0,
  'Food prepared and delivered from a restaurant': 0,
  'Amazon Appstore': 0,
  'Prime Now': 0,
  'Amazon Pay Places': 0,
  'Prime Wardrobe': 0,
  other: 0.04,
}
