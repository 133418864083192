import { handleActions } from 'redux-actions'
import fetch from '../../services/fetch'
import _ from 'lodash'
import { message } from 'antd'
import mapProducts from './mapProducts'
import initiateExport from './exportProducts'
import logger from '../../services/errorlogger'

export const actionTypes = {
  FETCH_PRODUCTS_LOADING: 'FETCH_PRODUCTS_LOADING',
  FETCH_PRODUCTS_DONE: 'FETCH_PRODUCTS_DONE',
}

export const actions = {
  fetchProducts: (jobId) => async (dispatch, getState) => {
    try {
      if (!jobId) return
      const { products, jobs } = getState()
      const hasTrialJob = jobs.jobs.trial
      if (!hasTrialJob && products.products[jobId]) return null
      dispatch({ type: actionTypes.FETCH_PRODUCTS_LOADING })

      const { data } = await fetch.get(`/products/${jobId}`)
      dispatch({ type: actionTypes.FETCH_PRODUCTS_DONE, products: data, jobId })
    } catch (e) {
      logger.logError(e)
    }
  },
  exportProducts: (data, config) => async (dispatch, getState) => {
    initiateExport(data, config)
    console.log('export products')
  },
  exportProductAlternatives: (data, config) => async (dispatch, getState) => {
    initiateExport(data, config)
    console.log('export product alternatives')
  },
}

export const getDefaultState = () => ({
  loading: true,
  products: {},
})

export default handleActions(
  {
    [actionTypes.FETCH_PRODUCTS_LOADING]: (state) => ({ ...state, loading: true }),
    [actionTypes.FETCH_PRODUCTS_DONE]: (state, { products, jobId }) => {
      const sortedProducts = _.sortBy(products, (p) => !p.affiliateId).reverse()
      const mappedProducts = mapProducts(sortedProducts)
      const nextProducts = { ...state.products, [jobId]: mappedProducts }
      return { ...state, products: nextProducts, loading: false }
    },
  },
  getDefaultState()
)
