import { handleActions } from 'redux-actions'
import history from '../history'
import logger from '../services/errorlogger'

import { actions as siteActions } from './SitesReducer'
import { actions as productsActions } from './Products/ProductsReducer'
import { actions as ModalsActions } from './GlobalModalsReducer'

import fetch from '../services/fetch'

export const actionTypes = {
  FETCH_JOB_LIST_LOADING: 'FETCH_JOB_LIST_LOADING',
  FETCH_JOB_LIST_DONE: 'FETCH_JOB_LIST_DONE',
  UPDATE_SITE_JOB: 'UPDATE_SITE_JOB',
  TRIAL_JOB_STARTED: 'TRIAL_JOB_STARTED',
  START_JOB_ERROR: 'START_JOB_ERROR',
  START_JOB_SUCCESS: 'START_JOB_SUCCESS',
  START_JOB_LOADING: 'START_JOB_LOADING',
}

export const actions = {
  fetchSingleJob: (jobId, siteId) => async (dispatch, getState) => {
    try {
      dispatch({ type: actionTypes.FETCH_JOB_LIST_LOADING })
      const { data } = await fetch.get(`/jobs/${jobId}`)
      if (!data) return
      dispatch({ type: actionTypes.FETCH_JOB_LIST_DONE, siteId, siteJobs: { [jobId]: data } })
    } catch (e) {
      logger.handleError(e)
    }
  },
  fetchExampleJob:
    ({ source = null, jobId = '-M8tOXoVM6Dg3LtXuFl7' }) =>
    async (dispatch, getState) => {
      try {
        const { data } = await fetch.post(`/jobs/${jobId}/example`, { source })
        console.log('JOB STARTED', data)
        console.log('jobId', data.jobId)
        dispatch({ type: actionTypes.TRIAL_JOB_STARTED, jobId: data.jobId })
        dispatch(actions.fetchSingleJob(data.jobId, 'trial'))
        dispatch(productsActions.fetchProducts(data.jobId))
      } catch (e) {
        logger.handleError(e)
      }
    },
  fetchSiteJobs: (siteId) => async (dispatch, getState) => {
    try {
      dispatch({ type: actionTypes.FETCH_JOB_LIST_LOADING })
      const { data } = await fetch.get(`/jobs/all/${siteId}`)
      dispatch({ type: actionTypes.FETCH_JOB_LIST_DONE, siteId, siteJobs: data })
    } catch (e) {
      logger.handleError(e)
    }
  },
  createJob:
    (site, source = 'manual') =>
    async (dispatch, getState) => {
      try {
        dispatch({ type: actionTypes.START_JOB_LOADING })
        const { settings } = getState()
        const allowedStartProject =
          settings && settings.permissions && settings.permissions.allowedStartProject
        if (!allowedStartProject) {
          dispatch(ModalsActions.toggleUpgradeModal('credits'))
          return dispatch({ type: actionTypes.START_JOB_ERROR })
        }

        const { siteId } = site
        const { data: jobId } = await fetch.put(`/jobs/`, { siteId, source })
        dispatch(actions.assignJobToSite(jobId, siteId))
      } catch (error) {
        // we are logging before here because i want to log every lack of credits message to sentry
        if (error.response && error.response.data.noCreditsLeft) {
          dispatch(ModalsActions.toggleUpgradeModal('credits'))
          logger.handleError(error, false)
          return dispatch({ type: actionTypes.START_JOB_ERROR })
        }
        logger.handleError(error)
      }
    },
  startJob:
    (jobId, withRedirect = true) =>
    async (dispatch, getState) => {
      try {
        dispatch({ type: actionTypes.START_JOB_LOADING })
        const { settings } = getState()
        const allowedStartProject =
          settings && settings.permissions && settings.permissions.allowedStartProject
        if (!allowedStartProject) {
          dispatch(ModalsActions.toggleUpgradeModal('credits'))
          return dispatch({ type: actionTypes.START_JOB_ERROR })
        }
        const { data } = await fetch.post(`/jobs/${jobId}`)
        console.log('JOB STARTED', data)
        dispatch({ type: actionTypes.START_JOB_SUCCESS })
        withRedirect && history.push('/app/dashboard/')
      } catch (error) {
        dispatch({ type: actionTypes.START_JOB_ERROR })

        // we are logging before here because i want to log every lack of credits message to sentry
        if (error.response && error.response.data.noCreditsLeft) {
          logger.handleError(error, false)
          return dispatch(ModalsActions.toggleUpgradeModal('credits'))
        }
        logger.handleError(error)
      }
    },
  startTrialJob:
    (siteUrl, { source = null, executeJS = false, campaign = null }) =>
    async (dispatch, getState) => {
      try {
        const sessionId = localStorage && localStorage.getItem('sessionId')
        const ip = getState().settings.userIp
        const { data } = await fetch.post(`/jobs/trial/run/`, {
          siteUrl,
          executeJS,
          source,
          campaign,
          sessionId,
          ip,
        })
        console.log('JOB STARTED', data)
        console.log('jobId', data.jobId)
        dispatch({ type: actionTypes.TRIAL_JOB_STARTED, jobId: data.jobId })
        dispatch(actions.fetchSingleJob(data.jobId, 'trial'))
      } catch (e) {
        logger.handleError(e)
      }
    },
  stopJob: (siteId, jobId) => async (dispatch, getState) => {
    try {
      // if (!jobId) return null;
      await fetch.delete(`/jobs/${jobId}`)
      const newValues = { latestJobStatus: 'cancelled' }
      if (siteId) dispatch(siteActions.editSite(siteId, newValues))
      if (siteId) dispatch(actions.fetchSiteJobs(siteId))
    } catch (e) {
      if (!siteId) logger.handleError(e)
    }
  },
  assignJobToSite: (jobId, siteId, values) => async (dispatch, getState) => {
    try {
      const { sites } = getState()
      const currentSite = sites.sites.find((s) => s.siteId === siteId)
      const currentSiteCrawlJobIds = currentSite.crawlJobIds || []
      const newValues = {
        latestJobStatus: 'scheduled',
        crawlJobIds: [...currentSiteCrawlJobIds, jobId],
      }
      // debugger;
      await dispatch(siteActions.editSite(siteId, newValues))
      await dispatch(actions.startJob(jobId))
      dispatch(actions.fetchSiteJobs(siteId))
    } catch (e) {
      logger.handleError(e)
    }
  },
  startJobManual: (siteId, jobId) => async (dispatch, getState) => {
    try {
      await fetch.patch(`/jobs/${jobId}`)
      console.log('JOB Started')
    } catch (e) {
      logger.handleError(e)
    }
  },
  removeJob: (siteId, jobId) => async (dispatch, getState) => {
    try {
      dispatch({ type: actionTypes.FETCH_JOB_LIST_LOADING })
      console.log('remove job', siteId, jobId)
      await fetch.post(`/jobs/${jobId}/delete/`)

      dispatch(siteActions.fetchAllSites(siteId))
      if (!siteId) return
      dispatch(actions.fetchSiteJobs(siteId))
    } catch (e) {
      logger.handleError(e)
    }
  },
}

export const getDefaultState = () => ({
  loading: true,
  startJobSuccess: false,
  startJobError: false,
  startJobLoading: false,
  trialJobId: null,
  jobs: {},
})

export default handleActions(
  {
    [actionTypes.FETCH_JOB_LIST_DONE]: (state, { siteJobs, siteId }) => {
      let nextSiteJobs = []
      if (siteJobs && Object.keys(siteJobs).length > 0) {
        nextSiteJobs = Object.keys(siteJobs).map((k) => ({
          ...siteJobs[k],
          jobId: k,
          jobLength: Math.abs(
            (siteJobs[k].dateStarted || siteJobs[k].dateScheduled) - siteJobs[k].dateStopped
          ),
        }))
      }
      // console.log(nextSiteJobs);
      const nextJobs = { ...state.jobs, [siteId]: nextSiteJobs }
      return { ...state, jobs: nextJobs, loading: false }
    },
    [actionTypes.UPDATE_SITE_JOB]: (state, { job, jobId }) => {
      const siteId = job.siteId
      const currentJob = {
        ...job,
        jobId,
        jobLength: Math.abs((job.dateStarted || job.dateScheduled) - job.dateStopped),
      }
      const nextSiteJobs = state.jobs[siteId].filter((j) => j.jobId !== jobId).concat(currentJob)
      const nextJobs = { ...state.jobs, [siteId]: nextSiteJobs }
      // debugger;
      return { ...state, jobs: nextJobs, loading: false }
    },
    [actionTypes.START_JOB_ERROR]: (state) => ({
      ...state,
      startJobSuccess: false,
      startJobLoading: false,
      startJobError: true,
    }),
    [actionTypes.START_JOB_LOADING]: (state) => ({
      ...state,
      startJobSuccess: false,
      startJobLoading: true,
      startJobError: false,
    }),
    [actionTypes.START_JOB_SUCCESS]: (state) => ({
      ...state,
      startJobSuccess: true,
      startJobLoading: false,
      startJobError: false,
    }),
    [actionTypes.FETCH_JOB_LIST_LOADING]: (state) => ({ ...state, loading: true }),
    [actionTypes.TRIAL_JOB_STARTED]: (state, { jobId }) => ({ ...state, trialJobId: jobId }),
  },
  getDefaultState()
)
