import { handleActions } from 'redux-actions'
import fetch from '../services/fetch'
import logger from '../services/errorlogger'

export const actionTypes = {
  FETCH_ANALYTICS_LOADING: 'FETCH_ANALYTICS_LOADING',
  FETCH_ANALYTICS_DONE: 'FETCH_ANALYTICS_DONE',
  UPDATE_ANALYTICS: 'UPDATE_ANALYTICS',
}

export const actions = {
  fetchAnalytics: () => async (dispatch, getState) => {
    try {
      const { userId } = getState().settings
      if (!userId) return
      dispatch({ type: actionTypes.FETCH_ANALYTICS_LOADING })
      // renamed into a from analytics because it gets blocked by adblockers
      const { data: analytics } = await fetch.get(`/a/`)

      dispatch({ type: actionTypes.FETCH_ANALYTICS_DONE, analytics })
    } catch (e) {
      logger.handleError(e)
    }
  },
}

export const getDefaultState = () => ({
  loading: true,
  analytics: {},
})

export default handleActions(
  {
    [actionTypes.FETCH_ANALYTICS_LOADING]: (state) => ({ ...state, loading: true }),
    [actionTypes.FETCH_ANALYTICS_DONE]: (state, { analytics }) => {
      const nextAnalytics = { ...analytics }
      return { ...state, analytics: nextAnalytics, loading: false }
    },
    [actionTypes.UPDATE_ANALYTICS]: (state, { analytics, id }) => {
      const nextAnalytics = { ...state.analytics, [id]: analytics }
      return { ...state, analytics: nextAnalytics }
    },
  },
  getDefaultState()
)
