import moment from 'moment'
import { convertArrayToCSV as toCsv } from 'convert-array-to-csv'
import papaparse from 'papaparse'
import { message } from 'antd'
import logger from '../../services/errorlogger'

const sanitizeValue = (v) => {
  if (typeof v === 'boolean') return v ? 'Yes' : 'No'
  if (!v) return 'N/A'
  if (typeof v === 'string') {
    // making the csv export safe for different readers
    let res = v
    if (res.indexOf(',') > -1) {
      res = res.split(',').join(' ')
    }
    if (res.indexOf(';') > -1) {
      res = res.split(';').join(' ')
    }
    if (res.indexOf('"') > -1) {
      res = res.split('"').join(' ')
    }
    res = res.replace(/(\r\n|\n|\r)/gm, ' ')
    return res
  }
  return v
}

const toObjectRow = (row, headerMapping) => {
  const result = Object.keys(headerMapping).reduce((prev, curr, index, array) => {
    return {
      ...prev,
      [headerMapping[curr]]: sanitizeValue(row[curr]),
    }
  }, {})
  return result
}

const mapAvailabilityData = (exportData) => {
  let headerMapping = {
    page: 'Source Page',
    to: 'Product Page',
    anchor: 'Anchor Text',
    affiliateId: 'Affiliate Id',
    name: 'Product Name',
    reviewCount: 'Reviews',
    reviewScore: 'Average Review',
    isAvailable: 'Available',
    isOutOfStock: 'Out Of Stock',
    is404: '404',
  }

  const tableAsJSON = []
  exportData.forEach((row) => {
    row.from.forEach((from) => {
      const rowAsObject = toObjectRow(
        {
          ...row,
          from: from,
          page: from.page || '',
          anchor: from.anchor || '',
          affiliateId: Boolean(from.affiliateId) ? from.affiliateId : row.affiliateId,
        },
        headerMapping
      )
      tableAsJSON.push(rowAsObject)
    })
  })

  const formattedForCSV = papaparse.unparse(tableAsJSON, {
    delimiter: ',',
    header: true,
    quotes: true,
    columns: Object.values(headerMapping),
  })
  return formattedForCSV
}

const mapAlternativesData = (exportData) => {
  const headerMapping = {
    name: 'Product Name',
    productLink: 'Alternative Product Link',
    alternativeStoreName: 'Alternative Store Name',
    signupPartner: 'Signup Partner',
    signupUrl: 'SignupUrl',
    terms: 'Terms',
  }

  const formatTerms = (terms) => {
    if (!terms || terms.length === 0) return 'Check Partner'
    if (terms.length > 1) {
      return `${Number(terms[0] * 100).toFixed(1)}% - ${Number(terms[1] * 100).toFixed(1)}%`
    }
    return `${Number(terms[0] * 100).toFixed(1)}%`
  }

  // test this
  const tableAsJSON = []
  exportData.forEach((row) => {
    if (!row.alternatives) {
      const rowAsObject = toObjectRow(row, headerMapping)
      return tableAsJSON.push(rowAsObject)
    }

    row.alternatives.forEach((a) => {
      const rowAsObject = toObjectRow(
        {
          ...row,
          productLink: a.advertiserProductLink,
          alternativeStoreName: a.name,
          signupPartner: a.signupPartner,
          signupUrl: a.signupUrl,
          terms: formatTerms(a.terms),
        },
        headerMapping
      )
      tableAsJSON.push(rowAsObject)
    })
  })

  const formattedForCSV = papaparse.unparse(tableAsJSON, {
    delimiter: ',',
    quotes: true,
    header: true,
    columns: Object.values(headerMapping),
  })

  return formattedForCSV
}

const createFile = (csv, fileName) => {
  var csvBlob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
  var csvURL = null
  if (navigator.msSaveBlob) {
    csvURL = navigator.msSaveBlob(csvBlob, fileName)
  } else {
    csvURL = window.URL.createObjectURL(csvBlob)
  }

  return csvURL
}

const startDownload = (csvURL, fileName) => {
  var tempLink = document.createElement('a')
  tempLink.href = csvURL
  tempLink.setAttribute('download', fileName)
  tempLink.click()
}

const initiateExport = (data, config) => {
  try {
    if (!data || !data.length) return message.error('Can not export: the list is empty')
    const { dateStarted, siteUrl, isAlternativeExport } = config

    const exportData = isAlternativeExport ? mapAlternativesData(data) : mapAvailabilityData(data)
    const date = moment(dateStarted).format('MMMM d')
    const fileName = `amzw-report-${siteUrl}-${date}.csv`
    const fileUrl = createFile(exportData, fileName)

    startDownload(fileUrl, fileName)
  } catch (e) {
    logger.logError(e)
    console.log('Error', e)
    message.error('Something went wrong while exporting', 2)
  }
}

export default initiateExport
