import feeSchedule from './feeSchedule'
import _ from 'lodash'

// covering these with tests is a good idea
const getAmazonCommission = (category, altCategory) => {
  const getCleanCategory = (c) => {
    let cat = [c]
    cat = _.flatten(cat.map((s) => s.split('-')))
    cat = _.flatten(cat.map((s) => s.split(' and ')))
    cat = _.flatten(cat.map((s) => s.split(' ')))
    cat = _.flatten(cat.map((s) => s.split('&')))
    return cat.map((s) => s.trim()).filter((s) => !!s)
  }

  const getCategoryMatch = (c) => {
    if (!c) return null

    const categoryWords = getCleanCategory(c)
    const categories = Object.keys(feeSchedule)
    const match = categories.find((k) =>
      categoryWords.some((w) => k.toLowerCase().includes(w.toLowerCase()))
    )
    // if (c === 'Video Games') {
    //     debugger;
    // }
    return match || null
  }

  const categories = Object.keys(feeSchedule)
  let categoryMatch = getCategoryMatch(category)
  if (!categoryMatch) categoryMatch = getCategoryMatch(altCategory)

  const result = categoryMatch ? feeSchedule[categoryMatch] : feeSchedule.other

  if (category !== 'electronics' && category !== 'aht' && category !== 'hpc' && !categoryMatch) {
    console.log('no match', category)
    // debugger;
  }

  // if (category === 'wireless-prepaid') {
  //     debugger;
  // }

  return result
}

const getAlaternativeTerms = (terms, c, geo = 'worldwide') => {
  if (!terms || !terms[geo]) return []
  const category = Boolean(c) ? c : 'all'
  // smart default here because some categories are not parsed correctly
  if (!category) {
    console.log(category)
    // debugger;
    // return null;
  }
  // console.log('terms=> cat', terms[geo], category);
  const matchCategory = Object.keys(terms[geo]).find((k) =>
    category
      .split(' ')
      .map((c) => c.trim().toLocaleLowerCase())
      .filter((c) => !!c && c !== '&')
      .some((c) => k.toLocaleLowerCase().indexOf(c) > -1)
  )

  const rates = terms[geo][category] || terms[geo][matchCategory] || terms[geo].all
  if (!rates) return []

  const { maxFlat, minFlat, maxPercent, minPercent } = rates
  // remove flat due to complexity of rendering on the frontend
  // if (maxFlat || minFlat) {
  // 	if (maxFlat !== minFlat) return [minFlat, maxFlat]
  // 	return [Math.max(maxFlat, minFlat)];

  // }

  if (maxPercent || minPercent) {
    if (maxPercent !== minPercent) return [minPercent, maxPercent]
    return [Math.max(maxPercent, minPercent)]
  }

  return []
}

const mapProducts = (data) => {
  const res = data.map((d) => {
    try {
      if (!d || !d.alternatives || d.alternatives.length === 0) return d
      // product level details
      // even if i failed to indentify a category, i still should provide as much data as i can to the users
      //
      const currentCommission = getAmazonCommission(d.category, d.altCategory)

      const terms = _.flatten(
        d.alternatives.map((a) => getAlaternativeTerms(a.terms, d.category))
      ).filter((f) => !!f)
      const maxCommission = Math.max(...terms)

      const commissionGain = (maxCommission - currentCommission) / currentCommission + 1
      if (isNaN(commissionGain)) {
        debugger
      }
      // alternative-level details
      const alternatives = d.alternatives.map((x) => {
        const alternativeTerms = getAlaternativeTerms(x.terms, d.category)
        const res = { ...x, terms: alternativeTerms }
        return res
      })
      return {
        ...d,
        currentCommission,
        maxCommission,
        terms,
        commissionGain,
        alternatives,
      }
    } catch (e) {
      debugger
      console.log(JSON.stringify(e.message))
      return d
    }
  })
  return res
}

export default mapProducts
