import React, { useState, useEffect } from 'react'
import { Spin, Button } from 'antd'
import { SyncOutlined } from '@ant-design/icons'

const Loading = ({ onRefresh = () => window.location.reload() }) => {
  const [showRefreshButton, setShowRefreshButton] = useState(false)

  useEffect(() => {
    // Set a timer to show the refresh button after 5 seconds
    const timer = setTimeout(() => {
      setShowRefreshButton(true)
    }, 5000)

    // Cleanup the timer when the component unmounts
    return () => clearTimeout(timer)
  }, [])

  const fadeInStyle = {
    opacity: showRefreshButton ? 1 : 0,
    transition: 'opacity 1s ease-in', // Control the fade-in effect
  }

  return (
    <div>
      <Spin />
      <div style={{ margin: '15px 0 20px 0' }}>Loading...</div>

      <div style={fadeInStyle}>
        <Button onClick={onRefresh} icon={<SyncOutlined style={{ fontSize: 14 }} />}>
          Refresh
        </Button>
      </div>
    </div>
  )
}

export default Loading
